<template>
  <div class="about">
    <div>
      <el-input style="width: 300px;margin: 0 10px 0 0"  v-model="keywords"></el-input>
      <el-select clearable style="width: 200px;margin-right: 10px" v-model="complain_type" placeholder="投诉类型">
        <el-option
            v-for="item in complainArr"
            :key="item.id"
            :label="item.name"
            :value="item.id">
        </el-option>
      </el-select>
      <el-select clearable style="margin:0 15px" v-model="area_id" @clear="clearOption" size="medium">
                <el-option
                    v-for="item1 in region"
                    :key="item1.id"
                    :label="item1.area_name"
                    :value="item1.area_id"
                    >
                </el-option>
              </el-select>
      <el-button @click="search()" type="primary" style="margin:10px 0;" icon="el-icon-search"></el-button>
    </div>
    <el-button  style="margin-bottom: 10px" size="mini" v-for="(item,i) in SearchStatusArr" :key="i" :class="item.status==status?'onserch':''" type="primary" plain @click="onOrderSerch(item.status)">{{item.title}} <span v-if="item.count>0" style="color: red">({{item.count}})</span></el-button>

    <el-table  height="calc(100vh - 295px)" :data="tableData" style="width: 100%;">
      <el-table-column prop="product_logo"  width="80" label="产品图" >
        <template #default="scope">
          <div style="height: 100%;display: flex;align-items:center;padding: 5px 0">
            <el-image
                v-if="scope.row.product_logo"
                style="width: 50px; height: 50px"
                :src="scope.row.product_logo"
                :preview-src-list="[scope.row.product_logo]">
            </el-image>
          </div>
        </template>
      </el-table-column>
      <el-table-column width="160" prop="order_id" label="订单ID" >
      </el-table-column>
      <el-table-column width="160" prop="order_no" label="订单编号" >
      </el-table-column>
      <el-table-column width="141" prop="product_name" label="产品名称" >
      </el-table-column>
      <el-table-column width="100" prop="type_name" label="投诉类型">
      </el-table-column>
      <el-table-column prop="status_text" label="状态" >
      </el-table-column>
      <el-table-column prop="remark" show-overflow-tooltip width="120" label="用户备注" >
      </el-table-column>
<!--      <el-table-column prop="user_remark" show-overflow-tooltip width="120" label="后台备注" >-->
<!--      </el-table-column>-->
      <el-table-column width="160" prop="create_time" label="申请时间" >
      </el-table-column>
      <el-table-column fixed="right" prop="address" label="操作" width="184">
        <template #default="scope">
          <div style="margin: 5px 0">
            <el-button-group>
              <el-button size="mini" type="primary" @click="batchStatus(scope.row)">投诉处理</el-button>
              <el-button size="mini" type="primary"  @click="upData(scope.row)">修改备注</el-button>
            </el-button-group>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <div style="margin-top: 20px">
      <el-pagination
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="page"
          :page-sizes="[10, 20, 30, 40,50]"
          :page-size="limit"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
      >
      </el-pagination>

    </div>
    <el-dialog
        title="修改状态"
        v-model="StatusT"
        width="30%"
        direction="rtl"
        :close-on-click-modal="false"
        destroy-on-close>
      <el-form
          :model="ruleForm"
          :rules="rules"
          ref="ruleForm"
          label-width="60px"
          class="demo-ruleForm">
        <el-form-item label="状态" prop="user_remark">
          <el-select  style="width:100%" v-model="status" >
            <el-option
                v-for="item in statusArr"
                :key="item.id"
                :label="item.name"
                :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" v-if="ruleForm.id" :loading="login" @click="okStatus('ruleForm',2)">修改</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
    <el-drawer
        title="修改备注"
        v-model="drawer"
        size="30%"
        direction="rtl"
        :close-on-click-modal="false"
        destroy-on-close>
      <el-form
          :model="ruleForm"
          :rules="rules"
          ref="ruleForm"
          label-width="100px"
          class="demo-ruleForm">
        <el-form-item label="备注" prop="user_remark">
          <el-input v-model="ruleForm.user_remark"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" v-if="ruleForm.id" :loading="login" @click="okAdd('ruleForm',2)">修改</el-button>
          <el-button type="primary" v-else :loading="login" @click="okAdd('ruleForm',1)">保存</el-button>
        </el-form-item>
      </el-form>
    </el-drawer>
  </div>
</template>
<script>
// @ is an alias to /src
import * as axios from "@/api/orderApi"//本页面接口
import {get,post,addDq} from "@/api/Api"
export default {
  name: 'brand',
  data(){
    return{
      //查询
      SearchStatusArr:{},
      complainArr:[],
      area_id:null,
      region:[],
      complain_type:'',
      status:'',
      //查询
      StatusT:false,
      login:false,
      drawer:false,
      tableData:[],
      page:1,
      limit:10,
      total:0,
      keywords:'',
      ruleForm:{
        user_remark:'',
      },
      type:[
        {
          id:0,
          name:'客户端',
        },
        {
          id:1,
          name:'师傅端',
        },
      ],
      statusArr:[
        {
          id:0,
          name:'发起投诉',
        },
        {
          id:1,
          name:'已受理',
        },
        {
          id:2,
          name:'已撤销',
        },
        {
          id:3,
          name:'完结',
        },
      ],
      rules:{
        user_remark: [
          { required: true, message: '请填写内容', trigger: 'change' },
        ],
      }
    }
  },
  created() {
    this.onTable()
    this.keyupSubmit()
    this.onArrFun()
    this.getaddDq()

  },

  methods:{
    onOrderSerch(val){
      this.page=1
      this.status=val
      this.onTable()
    },
    onArrFun(){
      let data={
        page:1,
        limit:1000,
        w_type:'base',
      }
      get(data,'/api/admin/complaintype').then((res)=>{
        // console.log(res)
        this.complainArr=res.data.data
      }).catch((err)=>{

      })
      get({area_id:this.area_id},'/api/admin/complain/getSearchStatus').then((res)=>{
        console.log(res)
        this.SearchStatusArr=res.data
      }).catch((err)=>{

      })
    },
    keyupSubmit(){
      document.onkeydown=e=>{
        let _key=window.event.keyCode;
        if(_key===13){
          this.search()
        }
      }
    },
    onTable(){
      let form={
        page:this.page,
        limit:this.limit,
        keywords:this.keywords,
        complain_type:this.complain_type,
        status:this.status,
        area_id:this.area_id

      }
      axios.getcomplainData(form).then((res)=>{
        console.log(res)
        this.tableData=res.data.data
        console.log('this.tableData',this.tableData)
        this.total=res.data.total
      }).catch((err)=>{

      })
    },
    search(){
      this.page=1
      this.onTable()
      this.onArrFun()
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`)
      this.limit=val
      this.onTable()
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`)
      this.page=val
      this.onTable()
    },
    add(){
      this.ruleForm={
        user_remark:'',
      }
      this.drawer=true
    },
    upData(row){
      this.ruleForm.id=row.id
      this.ruleForm.user_remark=row.user_remark
      this.drawer=true
    },
    batchStatus(row){
        this.ruleForm.id=row.id
        this.status=row.status
        this.StatusT=true
    },
    okStatus(row){
        post({id:this.ruleForm.id,status:this.status},'/api/admin/complain/batchStatus').then((res)=>{
          if(res.code==200){
            this.$message.success(res.msg)
            this.upData(this.rowData)
          }else {
            this.$message.error(res.msg)
          }
        }).catch((err)=>{
          this.$message.error('服务器报错！！请联系管理员')
        })
    },
    okAdd(formName,val){
      if(this.login){
        return false
      }
      this.login=true
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if(val==1){
            axios.postbatchRemark(this.ruleForm).then((res)=>{
              if(res.code==200){
                this.$message.success(res.msg)
                this.onTable()
                let that=this
                setTimeout(function () {
                  that.drawer=false
                  that.login=false
                },1000)
              }else {
                this.login=false
                this.$message.error(res.msg)
              }
            }).catch((err)=>{
              this.login=false
              this.$message.error('服务器报错！！请联系管理员')
            })
          }else {
            axios.postbatchRemark(this.ruleForm).then((res)=>{
              if(res.code==200){
                this.$message.success(res.msg)
                this.onTable()
                let that=this
                this.login=false
                setTimeout(function () {
                  that.drawer=false
                },1000)
              }else {
                this.login=false
                this.$message.error(res.msg)
              }
            }).catch((err)=>{
              this.login=false
              this.$message.error('服务器报错！！请联系管理员')
            })
          }

        } else {
          this.login=false
          console.log('error submit!!')
          return false
        }
      })
    },
    // 获取城市地区列表数据
    async getaddDq(){
      const {data:res} = await addDq()
      this.region = res
      this.area_id = this.region[0].area_id
      this.onTable()
      this.onArrFun()
    },
  }
}
</script>
<style lang="less" >
.onserch{
  background-color:#409eff ;
  color: white;
}
.avatar-uploader .el-upload {
  border: 1px dashed #c1c1c1;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.el-upload{

}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
.el-table .el-table__cell{
  padding: 0;
}
</style>